<template>
  <v-col cols="12" sm="10" md="8" lg="6" xl="6">
    <v-card flat>
      <v-card-title>
        <v-row align="center" justify="center">
          <h3>{{ $t('views.constants.register') }}</h3>
        </v-row>
      </v-card-title>
      <company-form :isEditMode="false"></company-form>
    </v-card>
  </v-col>
</template>
<script>
import companyForm from 'components/companies/Form.vue';

export default {
  name: 'Register',
  components: {
    companyForm
  }
};
</script>
